import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from '../../../../../lib/moment';
import {
  Alert,
  AlertTitle,
  Button,
  InputAdornment,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { lighten, Stack } from '@mui/system';
import {
  AutocompleteElement,
  DateTimePickerElement,
  FormContainer,
  RadioButtonGroup,
  SelectElement,
  TextFieldElement
} from 'react-hook-form-mui';
import useErrorHandling from '../../../../common/hooks/useErrorHandling';
import { toLessonOption } from '../../eventFunctions';
import { NILL, participantNeeds } from '../../../../../lib/constants';
import CheckboxButtonGroup from '../../../../common/HookFormMui/CheckboxButtonGroup';
import TextAreaLimiter from '../../../../common/TextAreaLimiter';
import { format, useNumberFormat } from '@react-input/number-format';

const EditCell = styled(TableCell)({
  border: `1px solid #0288d1 !important`, // info.main
});

const CompleteRow = ({ n, colA, colB, colC }) => (
  <TableRow>
    <TableCell>{`${n}. ${colA}`}</TableCell>
    <TableCell>{colB}</TableCell>
    <EditCell>{colC}</EditCell>
  </TableRow>
);

const TableComp = styled(Table)(({ theme }) => ({
  marginBottom: '32px',
  '& .MuiTableCell-root': {
    fontSize: theme.typography.pxToRem(16),
    padding: '10px 12px',
    '&.MuiTableCell-head': {
      padding: '16px',
    }
  }
}));

const TableHeadRow = styled(TableRow)({
  '& .MuiTableCell-root': {
    backgroundColor: lighten('rgb(200,200,200)', 0.9),
    '&:first-of-type': {
      fontWeight: 'bold',
    },
  },
});

const noUnderline = {
  '& .MuiInputBase-root.MuiInput-root': {
    '&::before, &.MuiInput-underline::after': { borderBottom: 'none' },
  },
};

const WORD_LIMIT = 250;

const getTextAreaHelperText = (words) => `${WORD_LIMIT} word limit (${words} word${words === 1 ? '' : 's'} used)`;

const CompleteSessionForm = ({ session, form, pearsData, boothTopics, valuesSetState, submitting, onSubmit }) => {
  const [wasSubmitting, setWasSubmitting] = useState(false);
  const [valuesSet, setValuesSet] = valuesSetState;

  const pickerOpenStart = useRef(false);
  const theme = useTheme();

  const currencyFormatRef = useNumberFormat({
    currency: 'USD',
    locales: 'en',
    maximumFractionDigits: 2,
  });

  const {
    handleSubmit,
    control,
    trigger,
    watch,
    setValue,
    getValues,
    clearErrors,
    formState: { errors, isSubmitting },
  } = form;

  const watchAsPlanned = watch('as_planned');
  const watchDemo = watch('demonstration');

  useErrorHandling({ errors, isSubmitting, wasSubmitting, setWasSubmitting });

  useEffect(() => {
    if (!session || valuesSet || !pearsData?.languages) {
      // session was not found, values alread prepared, or pears not ready
      return;
    } else if (!session.completed) {
      // No completed means we are creating one
      if (!valuesSet) {
        if (session.event.event_type === 'BOOTH') {
          setValue('delivery_method', ['In Person']);
        }
        setValue('participant_needs', session.event.participant_needs);
        setValue('participant_needs_other', session.event.participant_needs_other);
        setValuesSet(true);
      }
      return;
    }

    const getLang = (langId) => {
      const lang = pearsData.languages.find((l) => (l.id === langId || l.label === langId));
      return lang?.label || lang?.id || langId;
    };

    const completed = session.completed;
    const values = {
      as_planned: completed.as_planned ? 'true' : 'false',
      as_planned_detail: completed.as_planned_detail,
      participant_needs: completed.participant_needs,
      participant_needs_other: completed.participant_needs_other,
      participant_actions: completed.participant_actions,
      lessonID: completed.lessonID,
      lesson: completed.lesson ? toLessonOption(completed.lesson) : null,
      start: moment(completed.start),
      end: moment(completed.end),
      delivery_method: (session.event.event_type === 'BOOTH' ? ['In Person'] : completed.delivery_method),
      delivery_url: completed.delivery_url,
      delivery_language: completed.delivery_language.map(getLang),
      material_language: completed.material_language.map(getLang),
      delivery_location: completed.delivery_location,
      demonstration: completed.demonstration ? 'true' : 'false',
      recipes: completed.recipes,
      donations_received: completed.donations_received ? 'true' : 'false',
      donations_total: format(completed.donations_total || 0, {
        currency: 'USD',
        locales: 'en',
        maximumFractionDigits: 2,
      }),
      comments: completed.comments,
    };

    Object.keys(values).forEach((key) => setValue(key, values[key]));
    setValuesSet(true);
  }, [pearsData, session]);

  const onSubmitClick = (value) => {
    if (value === 'complete') {
      return handleSubmit((values) => {
        values.save = value;
        return onSubmit(values);
      });
    } else {
      return () => {
        const values = getValues();
        values.save = value;
        return onSubmit(values);
      }
    }
  }

  const handleStartChange = (value) => {
    if (!pickerOpenStart.current) {
      handleStartAccept(value);
    } else {
      if (!value) return true;
      trigger('start').then();
      trigger('end').then();
    }
  };

  const handleStartAccept = (value) => {
    if (!value) return true;
    const eventEnd = getValues('end');
    let newEnd = (eventEnd && eventEnd.isValid()) ? eventEnd.clone() : null;
    if (!eventEnd && value && value.isValid()) {
      newEnd = value.clone();
      newEnd.add(1, 'hours');
      setValue('end', newEnd);
    } else if (newEnd && value && value.isValid()) {
      newEnd.set({
        year: value.get('year'),
        month: value.get('month'),
        date: value.get('date'),
      }).format();
      setValue('end', newEnd);
    }
    trigger('start').then();
    trigger('end').then();
  };

  const lessonOptions = useMemo(() => {
    if (!session) {
      return [];
    }
    if (session.event.event_type === 'BOOTH') {
      return boothTopics.map((c) => ({
        id: c.id,
        label: c.booth_topic,
        audience: c.audience,
      }));
    } else {
      return session.event?.curriculum?.lessons?.items?.map((c) => ({
        id: c.id,
        label: c.lesson_name,
        audience: c.audience,
      }));
    }
  }, [session]);

  const languages = pearsData?.languageLabels;

  const isClass = session.event.event_type === 'CLASS';

  const readOnly = session.status === 'COMPLETED';

  const rows = [];

  const topRows = useMemo(() => {
    const tRows = [];
    tRows.push(
      (i) => <RadioButtonGroup
        key={`row-as_planned`}
        id="field-as_planned"
        name="as_planned"
        label={`${i}. Overall, did this session go the way you thought it would?`}
        variant={'standard'}
        control={control}
        disabled={readOnly}
        fullWidth
        required
        options={[
          { label: 'Yes', id: 'true' },
          { label: 'No', id: 'false' },
        ]}
      />
    );

    if (watchAsPlanned === 'false') {
      tRows.push(
        (i) => <TextAreaLimiter
          key={`row-as_planned_detail`}
          id="field-as_planned_detail"
          name="as_planned_detail"
          label={`${i}. Please share a bit more about why you selected No above.`}
          getHelperText={getTextAreaHelperText}
          wordLimit={WORD_LIMIT}
          variant={'standard'}
          control={control}
          rows={3}
          disabled={readOnly}
          fullWidth
          required
        />
      );
    }
    return tRows;
  }, [watchAsPlanned]);


  const needsAndActions = [];
  needsAndActions.push(
    (i) => <CheckboxButtonGroup
      key={`row-participant_needs`}
      label={`${i}. Add or remove needs to reflect what you have learned about this group so far.`}
      id="field-participant_needs"
      name="participant_needs"
      control={control}
      sx={{
        '& .MuiFormGroup-root': {
          flexDirection: 'row',
        },
        '& .MuiFormControlLabel-root': {
          width: '100%',
          [theme.breakpoints.up('md')]: {
            width: '49%',
          },
          [theme.breakpoints.up('lg')]: {
            width: '32%',
          }
        },
      }}
      disabled={readOnly}
      required
      fullWidth
      textFieldRequired
      textFieldInline
      options={participantNeeds}
    />
  );
  needsAndActions.push(
    (i) => <TextAreaLimiter
      id="field-participant_actions"
      name="participant_actions"
      key={`row-participant_actions`}
      label={`${i}. What actions have you taken to meet the specific needs of this group?`}
      getHelperText={getTextAreaHelperText}
      wordLimit={WORD_LIMIT}
      variant={'standard'}
      control={control}
      rows={3}
      disabled={readOnly}
      fullWidth
      required
    />
  );

  rows.push(
    (i) => <CompleteRow
      n={i}
      key={`row-lesson`}
      colA={isClass ? 'Lesson Taught' : 'Booth Topic'}
      colB={session.lesson.lesson_name || session.lesson.booth_topic}
      colC={
        <AutocompleteElement
          id="field-lesson"
          name="lesson"
          placeholder={'Choose curriculum'}
          textFieldProps={{ variant: 'standard' }}
          control={control}
          fullWidth
          required={isClass}
          options={lessonOptions}
          autocompleteProps={{
            isOptionEqualToValue: (option, newValue) => {
              return option?.id === newValue?.id;
            },
            disabled: readOnly,
            sx: noUnderline,
          }}
        />
      }
    />
  );

  rows.push(
    (i) => <CompleteRow
      n={i}
      key={`row-start`}
      colA="Start Date & Time"
      colB={moment(session.start).format('M/D/YYYY h:mmA')}
      colC={
        <DateTimePickerElement
          id="field-start"
          name="start"
          // label={'What is the planned start date & time for this session?'}
          placerholder=""
          control={control}
          required
          disabled={readOnly}
          validation={{
            validate: {
              range: (value) => {
                const endDate = getValues('end');
                if (!value || !endDate) return true;
                const valid = !value.isAfter(endDate);
                return valid || 'Start Date & Time must be less than End Date & Time';
              }
            }
          }}
          onOpen={() => {
            pickerOpenStart.current = true;
          }}
          onClose={() => {
            pickerOpenStart.current = false;
          }}
          onAccept={readOnly ? null : handleStartAccept}
          onChange={readOnly ? null : handleStartChange}
          inputProps={{
            variant: 'standard',
            fullWidth: true,
            id: 'field-start',
            sx: noUnderline,
          }}
        />
      }
    />
  );

  rows.push(
    (i) => <CompleteRow
      n={i}
      key={`row-end`}
      colA="End Date & Time"
      colB={moment(session.end).format('M/D/YYYY h:mmA')}
      colC={
        <DateTimePickerElement
          id="field-end"
          name="end"
          // label={'What is the planned end date & time for this session'}
          // helperText={'End Date & Time must be greater than Start Date & Time.'}
          control={control}
          required
          disabled={readOnly}
          validation={{
            validate: {
              range: (value) => {
                const eventStart = getValues('start');
                if (!value || !eventStart) return true;
                const valid = value.isAfter(eventStart);
                return valid || 'End Date & Time must be greater than Start Date & Time';
              }
            }
          }}
          onChange={() => {
            trigger('start').then();
            trigger('end').then();
          }}
          inputProps={{
            variant: 'standard',
            fullWidth: true,
            id: 'field-end',
            sx: noUnderline,
          }}
        />
      }
    />
  );

  rows.push(
    (i) => <CompleteRow
      n={i}
      key={`row-delivery_method`}
      colA="Delivery Method"
      colB={session.delivery_method}
      colC={
        <AutocompleteElement
          id="field-delivery_method"
          name="delivery_method"
          control={control}
          required={isClass}
          fullWidth
          multiple
          // label="What method will be used to deliver this program? Select all that apply."
          onChange={() => null}
          options={[
            'In Person',
            'Virtual/Live Online',
          ]}
          textFieldProps={{
            variant: 'standard',
          }}
          autocompleteProps={{
            isOptionEqualToValue: (option, newValue) => {
              return option === newValue;
            },
            disabled: !isClass || readOnly,
            sx: noUnderline,
          }}
        />
      }
    />
  );

  rows.push(
    (i) => <CompleteRow
      n={i}
      key={`row-delivery_language`}
      colA="Session Delivery Language(s)"
      colB={session.delivery_language?.join(', ')}
      colC={
        <AutocompleteElement
          id="field-delivery_language"
          name="delivery_language"
          control={control}
          required
          fullWidth
          multiple
          options={languages}
          onChange={() => null}
          textFieldProps={{
            variant: 'standard',
          }}
          autocompleteProps={{
            isOptionEqualToValue: (option, newValue) => {
              return option === newValue;
            },
            disabled: readOnly,
            sx: noUnderline,
          }}
        />
      }
    />
  );

  rows.push(
    (i) => <CompleteRow
      n={i}
      key={`row-material_language`}
      colA="Participant Material Language(s)"
      colB={session.material_language?.join(', ')}
      colC={
        <AutocompleteElement
          id="field-material_language"
          name="material_language"
          control={control}
          required
          fullWidth
          multiple
          options={languages}
          onChange={() => null}
          textFieldProps={{
            variant: 'standard',
          }}
          autocompleteProps={{
            isOptionEqualToValue: (option, newValue) => {
              return option === newValue;
            },
            disabled: readOnly,
            sx: noUnderline,
          }}
        />
      }
    />
  );

  if (session.delivery_method?.includes('In Person')) {
    rows.push(
      (i) => <CompleteRow
        n={i}
        key={`row-delivery_location`}
        colA="Session Delivery Site"
        colB={session.delivery_location || NILL}
        colC={
          <AutocompleteElement
            id="field-delivery_location"
            name="delivery_location"
            // label="Where will this session be delivered?"
            control={control}
            fullWidth
            required={session.delivery_method?.includes('In Person')}
            variant="standard"
            options={pearsData?.partners || []}
            autocompleteProps={{
              isOptionEqualToValue: (option, newValue) => {
                return option === newValue;
              },
              disabled: readOnly,
              sx: noUnderline,
            }}
            textFieldProps={{
              variant: 'standard',
            }}
          />
        }
      />
    );
  }

  if (session.delivery_method?.includes('Virtual/Live Online')) {
    rows.push(
      (i) => <CompleteRow
        n={i}
        key={`row-delivery_url`}
        colA="Session URL/Link"
        colB={session.delivery_url || NILL}
        colC={
          <TextFieldElement
            id="field-delivery_url"
            name="delivery_url"
            // label="What is the URL or link for this session?"
            variant="standard"
            control={control}
            disabled={readOnly}
            required={session.delivery_method?.includes('Virtual/Live Online')}
            fullWidth
          />
        }
      />
    );
  }

  rows.push(
    (i) => <CompleteRow
      n={i}
      key={`row-demonstration`}
      colA="Food Demonstration/Taste Test Included"
      colB={session.demonstration ? 'Yes' : 'No'}
      colC={
        <SelectElement
          id="field-demonstration"
          name="demonstration"
          variant={'standard'}
          control={control}
          fullWidth
          required
          disabled={readOnly}
          options={[
            { label: 'Yes', id: 'true' },
            { label: 'No', id: 'false' },
          ]}
          onChange={(value) => {
            if (value !== 'true') {
              clearErrors('recipes');
            }
          }}
          sx={noUnderline}
        />
      }
    />
  );

  rows.push(
    (i) => <CompleteRow
      n={i}
      key={`row-recipes`}
      colA="Recipe(s) Used"
      colB={session?.recipes || 'None'}
      colC={
        <TextFieldElement
          id="field-recipes"
          name="recipes"
          variant={'standard'}
          control={control}
          required={watchDemo === 'true'}
          validation={{
            required: false,
            validate: {
              required: (value) => watchDemo !== 'true' || !!value || 'This field is required',
            }
          }}
          fullWidth
          disabled={readOnly}
          sx={noUnderline}
        />
      }
    />
  );

  rows.push(
    (i) => <CompleteRow
      n={i}
      key={`row-donations_received`}
      colA="External Donations Received"
      colB="Not Applicable"
      colC={
        <SelectElement
          id="field-donations_received"
          name="donations_received"
          variant={'standard'}
          control={control}
          fullWidth
          required
          disabled={readOnly}
          options={[
            { label: 'Yes', id: 'true' },
            { label: 'No', id: 'false' },
          ]}
          sx={noUnderline}
        />
      }
    />
  );

  rows.push(
    (i) => <CompleteRow
      n={i}
      key={`row-donations_total`}
      colA="Total Value of Donations Received"
      colB="Not Applicable"
      colC={
        <TextFieldElement
          id="field-donations_total"
          name="donations_total"
          variant={'standard'}
          control={control}
          inputRef={currencyFormatRef}
          fullWidth
          required
          disabled={readOnly}
          sx={noUnderline}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      }
    />
  );

  const bottomRows = [];
  bottomRows.push(
    (i) => <TextAreaLimiter
      key={`row-comments`}
      id="field-comments"
      name="comments"
      label={`${i}. Add any other comments about this session that you want to share.`}
      variant={'standard'}
      control={control}
      getHelperText={getTextAreaHelperText}
      wordLimit={WORD_LIMIT}
      rows={4}
      disabled={readOnly}
      fullWidth
    />
  );

  let rowCount = 0;

  const iterate = (rowItems, rowWrap = false, noBorder = false) => rowItems.map((row) => {
    rowCount += 1;
    const element = row(rowCount);
    if (rowWrap) {
      return (
        <TableRow key={`table-row-${element.props.name}`}>
          <TableCell sx={noBorder ? { borderBottom: 'none' } : {}}>
            {element}
          </TableCell>
        </TableRow>
      );
    }
    return element;
  });

  return (
    <>
      <Alert severity="info" sx={{ marginBottom: '16px' }}>
        <AlertTitle>Instructions</AlertTitle>
        Confirm and review what you planned vs. what actually happened for this event.
      </Alert>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <FormContainer onSuccess={onSubmitClick('save')} FormProps={{ className: 'mui' }}>
          <TableComp>
            <TableBody>
              {iterate(topRows, true, true)}
            </TableBody>
          </TableComp>
          <TableComp>
            <TableHead>
              <TableHeadRow>
                <TableCell>Group Needs Identified & Actions Taken to Address Needs</TableCell>
              </TableHeadRow>
            </TableHead>
            <TableBody>
              {iterate(needsAndActions, true, true)}
            </TableBody>
          </TableComp>
          <TableComp>
            <TableHead>
              <TableHeadRow>
                <TableCell>Confirm Session Details</TableCell>
                <TableCell>Planned</TableCell>
                <TableCell sx={{ borderBottom: 'none' }}>Actual</TableCell>
              </TableHeadRow>
            </TableHead>
            <TableBody sx={{ '& .MuiTableCell-root:last-of-type': { width: '40%' } }}>
              {iterate(rows, false)}
            </TableBody>
          </TableComp>
          <TableComp>
            <TableBody>
              {iterate(bottomRows, true, true)}
            </TableBody>
          </TableComp>
        </FormContainer>
        <Alert severity="warning" sx={{ marginBottom: '16px' }}>
          Once you mark a session as complete, it can no longer be changed or modified.
        </Alert>
        <Stack spacing={2} direction="row">
          <Button
            id="button-save"
            disabled={submitting || readOnly}
            variant="contained"
            size="small"
            sx={{ marginTop: '20px' }}
            onClick={onSubmitClick('save')}
          >
            Save
          </Button>
          <Button
            id="button-submit"
            name="save"
            value="complete"
            disabled={submitting || readOnly}
            variant="contained"
            size="small"
            sx={{ marginTop: '20px' }}
            onClick={onSubmitClick('complete')}
          >
            Save & Complete Session
          </Button>
        </Stack>
      </LocalizationProvider>
    </>
  );
};

export default CompleteSessionForm;
