import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { PageContext } from '../../../../../lib/contexts/pageContext';
import { Alert, Button, Chip, Link, List, ListItem, Paper, Tab, Tabs, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import moment from '../../../../../lib/moment';
import { useAbility } from '@casl/react';
import { getStaffNames, graphQuery } from '../../../../../lib/api';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CheckIcon from '@mui/icons-material/Check';
import { AbilityContext, Subjects, toGraphQuery } from '../../../../../lib/permissions';
import UserContext from '../../../../../lib/contexts/userContext';
import { createFilter, fullDateFormat, scrollToAlert, } from '../../../../../lib/utils';
import useFormModal from '../../../../common/hooks/useFormModal';
import RadioButtonGroup from '../../../../common/HookFormMui/RadioButtonGroup';
import {getSessionName, isYouth, updateSession} from '../sessionFunctions';
import { TextFieldElement } from 'react-hook-form-mui';
import { cancelSession, listEvents } from '../../eventQueries';
import { orderSessions } from '../../eventFunctions';

const SessionDetails = ({ setTitle }) => {
  const [session, setSession] = useState(null);
  // const [deleting, setDeleting] = useState(false);
  const [cancelling, setCanceling] = useState(false);

  const { authenticated, userState: { userRecord } } = useContext(UserContext);

  const navigate = useNavigate();

  const {
    pageLoading,
    authorized,
    setBreadcrumbValue,
    setPageMessage,
    setPageErrorMessage,
  } = useContext(PageContext);

  const { eventID, sessionID } = useParams();

  const ability = useAbility(AbilityContext);

  const formModal = useFormModal();

  const filterRules = useMemo(() => {
    if (!eventID || !sessionID || !authenticated) {
      return {};
    }
    // Editing an event has the same permission as editing a session
    return toGraphQuery(ability, 'edit', Subjects.EVENT);
  }, [authenticated, sessionID, eventID, ability]);

  const fetchSession = useCallback(async () => {
    // Filter the event based on user permissions (if they can't view it, it won't return)
    const filter = createFilter(eventID, filterRules);
    const result = await graphQuery(listEvents, { filter, limit: 9999 });
    const [eventData] = result || null;
    const sessionData = eventData.sessions.items.find((s) => s.id === sessionID);
    // Replace staff emails with names from myINEP or Cognito
    if (sessionData) {
      sessionData.event = eventData;
      const names = await getStaffNames([sessionData.created_by, sessionData.updated_by, sessionData.canceled_by, sessionData.completed_by]);
      sessionData.created_by_names = names[sessionData.created_by];
      sessionData.updated_by_names = names[sessionData.updated_by];
      sessionData.canceled_by_names = names[sessionData.canceled_by];
      sessionData.completed_by_names = names[sessionData.completed_by];
      sessionData.totalNonCanceled = sessionData.event.sessions.items.filter((s) => s.status !== 'CANCELED').length;
      sessionData.mStart = moment(sessionData.start);
      sessionData.mEnd = moment(sessionData.end);
      sessionData.multiday = sessionData.mEnd.diff(sessionData.mStart, 'minutes') > ((24*60) - 1);
      sessionData.sessionOrder = null;
      if (sessionData.status !== 'CANCELED') {
        const sessions = sessionData.event.sessions.items;
        const nonCanceled = orderSessions(sessions.filter((s) => s.status !== 'CANCELED'));
        sessionData.sessionOrder = nonCanceled.findIndex((os) => os.id === sessionData.id) + 1;
      }
    }

    return sessionData;
  }, [eventID, sessionID, filterRules]);

  useEffect(() => {
    // Wait for permissions to get loaded for the users role before doing permission checks
    if (!eventID || !sessionID || !authenticated) {
      return;
    }

    let active = true;

    // Try to get the query filter first in case the userRole hasn't been set and permissions haven't been updated
    if (ability.cannot('edit', Subjects.EVENT) && !filterRules) {
      // Staff that can only edit their own events will NOT have the edit EVENT permission because it's conditional
      // Therefore there must be a non-null filter
      return;
    }

    fetchSession().then((data) => {
      if (active) {
        if (data) {
          setSession(data);
        } else {
          setSession('');  // shows "not found" message
        }
      }
    }).catch((err) => {
      if (!active) {
        return;
      }
      console.log('[SessionDetails] error fetching session:', err);
      setSession('');
      setTitle('Error Accessing Page');
    });

    return () => {
      active = false;
    }
    // eslint-disable-next-line
  }, [fetchSession, authenticated, sessionID, eventID, ability, filterRules]);

  useEffect(() => {
    if (session) {
      setBreadcrumbValue(':event', session.event);
      setBreadcrumbValue(':session', session);
    }
    // eslint-disable-next-line
  }, [session]);

  const stringsToOptions = (labels) => labels.map((label) => ({ id: label, label }));

  const handleCancel = async () => {
    const fields = (control) => [
      <RadioButtonGroup
        key="key-cancel_reasons"
        id="field-cancel_reason"
        name="cancel_reason"
        label={'Why is this session canceled?'}
        formLabelProps={{
          component: 'h2',
          style: {
            marginTop: 0,
            fontSize: '20px',
          }
        }}
        placeholder={'Select reason'}
        variant={'standard'}
        control={control}
        fullWidth
        required
        otherTextField
        otherTextFieldLabel={'If Other, please describe reason below*'}
        options={stringsToOptions([
          'No participants showed up',
          'Partner requested to cancel',
          'Staffing emergency',
          'Created by mistake',
          'Other',
        ])}
      />
    ];
    const cancelReason = await formModal({
      fields,
      confirmationText: 'CANCEL SESSION',
      cancellationText: 'KEEP SESSION',
    })
      .then(({ cancel_reason = null, other_text = null }) => other_text || cancel_reason)
      .catch(() => null);

    if (cancelReason) {
      setCanceling(true);
      const updates = {
        id: session.id,
        status: 'CANCELED',
        cancel_reason: cancelReason,
        canceled_by: userRecord.email,
        canceledAt: moment().toISOString(true),
      };

      await graphQuery(cancelSession, { input: updates })
        .then((result) => {
          setPageMessage('Session Canceled');

          fetchSession().then((data) => {
            console.log('session', data);
            setSession(data);
          }).catch((err) => {
            console.log('[SessionDetails] error fetching session:', err);
            setSession('');
            setTitle('Error Accessing Page');
          });
        })
        .catch((err) => {
          setPageErrorMessage('Error canceling session');
          console.log('[SessionDetails][cancel] error:', err);
        });
      scrollToAlert();
      setCanceling(false);
    }
    return false;
  };

  // const handleDelete = async () => {
  //   if (ability.cannot('delete', subject(Subjects.EVENT, session))) {
  //     setPageErrorMessage('You do not have permission to delete this event.');
  //     return false;
  //   }
  //   setDeleting(true);
  //   let deleted = false;
  //   try {
  //     const result = await API.graphql({
  //       ...graphqlOperation(deleteEvent, { input: { id: eventID } }),
  //       authMode: 'AMAZON_COGNITO_USER_POOLS',
  //     });
  //     if (result.data.deleteEvent?.id) {
  //       deleted = true;
  //     }
  //   } catch (err) {
  //     console.log('Error: ', err);
  //   }
  //   if (deleted) {
  //     setPageMessage('Event was deleted successfully.');
  //     setSession('');
  //   } else {
  //     setPageErrorMessage('An error occurred while deleting event. Please contact <support email address> for assistance.');
  //   }
  //   setDeleting(false);
  // };

  const sessionName = getSessionName({
    session,
    showNumbers: false,
    dateFallback: true,
    useNill: false,
  });

  const getDateHeader = () => {
    if (!session) {
      return '';
    }
    return `${fullDateFormat(session.mStart)} - ${session.multiday ? fullDateFormat(session.mEnd) : session.mEnd.format('h:mm A')}`;
  };

  const getAudienceChip = () => {
    if (session.event.event_type !== 'CLASS') {
      return null;
    }
    switch (session.lesson?.audience) {
      case 'Adults':
        return <Chip label="Adult Program" />;
      case 'Youth':
        return <Chip label="Youth Program" />;
      case 'Family':
        return <Chip label="Family Program" />;
      default:
        return null;
    }
  };

  const formatDeliverySite = () => session.delivery_location.replaceAll(/([^(]+)\(([^)]+)\)/g, '$1 | $2');

  const { participantVerbiage, showParticipantCount } = useMemo(() => {
    const result = { participantVerbiage: null, showParticipantCount: false };
    if (!session) {
      return result;
    }
    result.participantVerbiage = isYouth(session) ? 'Youth' : 'Adult';
    if (session.event.event_type === 'BOOTH') {
      result.showParticipantCount = true;
    } else if (session.event.event_type === 'CLASS') {
      const audience = session?.lesson.audience;
      result.showParticipantCount = audience === 'Youth';
    }
    return result;
  }, [session]);

  const handleParticipantCountModal = async () => {
    const fields = (control) => [
      <TextFieldElement
        id="field-participant_count"
        key="field-participant_count"
        name="participant_count"
        label={`Total ${participantVerbiage} Count`.toUpperCase()}
        variant={'standard'}
        helperText={`Total number of ${participantVerbiage.toLowerCase()} participants for this session.`}
        control={control}
        type="number"
        required
        validation={{
          validate: {
            integersOnly: (value) => {
              if (value !== Math.floor(value)) {
                return 'Please enter a whole number.';
              }
              return true;
            }
          },
          min: {
            // Note: The client asked to only allow positive numbers here. This means that
            // there is no way to change the count back to zero if the user accidentally
            // entered a value they didn't want.
            value: 1,
            message: 'Please enter a positive number.'
          },
          max: {
            // Huge numbers cause API errors.
            value: 99999,
            message: 'Please enter a number below 100,000.'
          }
        }}
        onKeyDown={(e) => {
          // <input type="number"> allows some mathematical chars like +-.e which we don't want.
          // This prevents users from entering those chars, while still allowing keys like
          // backspace, arrows, etc. to work.
          ["e", "E", "+", "-", ".", " "].includes(e.key) && e.preventDefault();
        }}
        data-cy="participant-count-field"
      />
    ];
    const participantCount = await formModal({
      fields,
      defaultValues: {
        participant_count: session?.participant_count || '',
      },
      confirmationText: 'SAVE CHANGES',
      cancellationText: 'CLOSE',
    })
      .then(({ participant_count }) => participant_count)
      .catch(() => null);

    if (participantCount) {
      await graphQuery(updateSession, {
        input: {
          id: session.id,
          participant_count: participantCount,
        },
      })
        .then((result) => {
          session.participant_count = result.participant_count;
          setSession({ ...session });
          setPageErrorMessage('');
          setPageMessage(`${participantVerbiage} counts saved.`);
        })
        .catch((err) => {
          setPageErrorMessage('Error saving participant count');
          console.log('[SessionDetails][participantCount] error:', err);
        });
      scrollToAlert();
    }
  }

  const handleComplete = () => {
    if (moment().isAfter(moment(session.end))) {
      // is end date in the past
      navigate(`/events/${session.event.id}/${sessionID}/complete`);
    } else {
      formModal({
        fields: () => [
          <Typography variant="h6" sx={{ marginBottom: '16px'}} key="title">
            You cannot complete this session
          </Typography>,
          <Box sx={{ width: '100%' }} key="content">
            You cannot complete this session because it has not yet ended.
          </Box>
        ],
        confirmationText: 'Close',
        hideCancelButton: true,
      });
    }
  };

  const incomplete = !['COMPLETED', 'CANCELED'].includes(session?.status);

  return (
    <div id="pagewrapper" className={'with-two-sidebar'} style={{ padding: 0 }}>
      <div id="content-wrapper" className={'event-details-card'}>
        {authorized && session && session !== '' && (
          <>
            { session.status === 'CANCELED' && (
              <Alert severity="warning" sx={{ marginBottom: '24px' }}>
                This session is canceled.
                <br/>
                Cancellation Reason: { session.cancel_reason }
                <br/>
                Canceled on: { moment(session.canceledAt).format('M/D/YY HH:mm a') } by { session.canceled_by_names }
              </Alert>
            )}
            { session.status === 'COMPLETED' && (
              <Alert severity="success" sx={{ marginBottom: '24px' }}>
                This session is completed. <Link to={`/events/${session.eventID}/${session.id}/complete`} component={RouterLink}>View completed session details</Link>
                <br/>
                Completed on: { moment(session.completedAt).format('M/D/YY HH:mm a') } by { session.completed_by_names }
              </Alert>
            )}
            <Paper elevation={3}>
              <Box padding={3}>
                <Typography variant="h4" color="ilstorm.main" mb={0} data-cy="session-name">
                  {sessionName}
                </Typography>
                <Typography variant="h6" color="blue.main" mb={4} data-cy="session-dates">
                  {getDateHeader()}
                </Typography>
                <Stack direction="row" spacing={1} data-cy="chips">
                  { session.delivery_method?.includes('In Person') && (
                    <Chip label="In Person" />
                  )}
                  { session.delivery_method?.includes('Virtual/Live Online') && (
                    <Chip label="Virtual/Live Online" />
                  )}
                  {!!session.sessionOrder && (
                    <Chip label={`Session ${session.sessionOrder} of ${session.totalNonCanceled}`} />
                  )}
                  { getAudienceChip() }
                </Stack>
                <List dense={true}>
                  { session.delivery_method?.includes('In Person') && (
                    <ListItem disablePadding data-cy="delivery-location">
                      {formatDeliverySite()}
                    </ListItem>
                  )}
                  { session.delivery_method?.includes('Virtual/Live Online') && (
                    <>
                      <ListItem disablePadding data-cy="delivery-url">
                        {session.delivery_url}
                      </ListItem>
                      <ListItem disablePadding data-cy="delivery-other-details">
                        {session.other_details}
                      </ListItem>
                    </>
                  )}
                  <ListItem disablePadding data-cy="languages">
                    Language(s): {session.delivery_language.join(', ')}
                  </ListItem>
                  <ListItem disablePadding>
                    Session Code: {session.short_code}
                  </ListItem>
                  <ListItem disablePadding>
                    Last Modified: {`${session.updated_by_names}, ${moment(session.updatedAt).format('MM/D/y h:mm A')}`}
                  </ListItem>
                </List>
                <Stack direction="row" justifyContent="space-between" sx={{ marginTop: '20px' }}>
                  <Button
                    variant="filled"
                    startIcon={<EditIcon />}
                    component={RouterLink}
                    to={`/events/${session.event.id}/${sessionID}/edit`}
                  >
                    Edit Session
                  </Button>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={2}
                  >
                    { incomplete && (
                      <Button
                        variant="filled"
                        color="error"
                        startIcon={<ClearIcon />}
                        disabled={cancelling}
                        onClick={handleCancel}
                      >
                        Cancel Session
                      </Button>
                    )}
                    { incomplete && (
                      <Button
                        variant="filled"
                        color="success"
                        startIcon={<CheckIcon />}
                        onClick={handleComplete}
                      >
                        Complete Session
                      </Button>
                    )}
                  </Stack>
                </Stack>
              </Box>
            </Paper>
            {showParticipantCount && (
              <>
                <Tabs
                  value={0}
                  textColor="primary"
                  indicatorColor="primary"
                  sx={{
                    marginTop: '32px',
                    '.MuiButtonBase-root': {
                      fontSize: '18px'
                    }
                  }}
                >
                  <Tab label="PARTICIPANTS" value={0} />
                </Tabs>
                <Stack row="column" spacing={2} mt="28px" width="auto" data-cy="participant-section">
                  <Typography variant="body">
                    Total {participantVerbiage} Count = {session?.participant_count || 0}
                  </Typography>
                  <Button
                    variant="filled"
                    startIcon={<PersonAddIcon />}
                    sx={{ width: '200px' }}
                    onClick={handleParticipantCountModal}
                    data-cy="participant-count-button"
                  >
                    Edit {participantVerbiage} Count
                  </Button>
                </Stack>
              </>
            )}
          </>
        )}
        { (!pageLoading && session === '') && (
          <>
            You do not have access, or the session was not found.
          </>
        )}
      </div>
    </div>
  );
};

export default SessionDetails;
