import React, { useContext, useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Box } from '@mui/system';
import { Alert, CircularProgress, Link } from '@mui/material';
import { AbilityContext, Subjects } from '../../../../../lib/permissions';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import useEventSessionQuery from '../../../../common/hooks/useEventSessionQuery';
import { useForm } from 'react-hook-form';
import { scrollToAlert, sortDisplayOrder } from '../../../../../lib/utils';
import moment from '../../../../../lib/moment';
import CompleteSessionForm from './CompleteSessionForm';
import { subject } from '@casl/ability';
import { useQuery } from '@tanstack/react-query';
import UserContext from '../../../../../lib/contexts/userContext';
import { getBoothTopics, getPearsData } from '../../eventFunctions';
import { useAbility } from '@casl/react';
import { PageContext } from '../../../../../lib/contexts/pageContext';
import { graphQuery } from '../../../../../lib/api';
import { createSessionCompleted, updateSessionCompleted } from '../../../../../graphql/mutations';
import useInvalidateQuery from '../../../../common/hooks/useInvalidateQuery';
import { updateSession } from '../sessionFunctions';

const CompleteSession = ({ setTitle }) => {
  const [submitting, setSubmitting] = useState(false);
  const valuesSetState = useState(null);

  const { eventID, sessionID } = useParams();

  const { authenticated, userState: { userRecord } } = useContext(UserContext);
  const { setPageMessage, setPageErrorMessage, setBreadcrumbValue } = useContext(PageContext);``

  const ability = useAbility(AbilityContext);
  const invalidateQueries = useInvalidateQuery();
  const navigate = useNavigate();

  const form = useForm({
    mode: 'onSubmit',
    shouldFocusError: false,
    defaultValues: {
      as_planned: null,
      as_planned_detail: null,
      lessons: null,
      start: null,
      end: null,
      participant_needs: [],
      participant_needs_other: null,
      participant_actions: null,
      delivery_method: [],
      delivery_url: null,
      delivery_location: null,
      delivery_language: [],
      material_language: [],
      demonstration: null,
      recipes: null,
      donations_received: null,
      donations_total: null,
      comments: null,
    }
  });

  const {
    data: session,
    loading,
    // refetching,
    error,
    isError,
  } = useEventSessionQuery({
    permissions: {
      id: eventID,
      action: 'edit',
      subject: Subjects.EVENT,
    },
    secondaryQueryFilter: {
      id: { eq: sessionID },
    },
    selectFn: (events) => {
      if (!events || events.length === 0) {
        return false;
      }
      if (events[0].sessions && events[0].sessions.items.length > 0) {
        const event = events[0];
        const session = event.sessions.items[0];
        delete event.sessions;
        sortDisplayOrder(event.curriculum?.lessons?.items);
        session.event = event;
        return session;
      }
      return false;
    },
  });

  const boothQuery = useQuery({
    queryKey: ['boothTopics'],
    queryFn: getBoothTopics,
    enabled: authenticated,
    structuralSharing: false,
    staleTime: 3 * 60 * 1000,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const {
    data: pearsData,
    isLoading: pearsLoading,
    error: pearsError,
    isError: pearsIsError,
  } = useQuery({
    enabled: authenticated,
    structuralSharing: false,
    staleTime: 5 * 60 * 1000,
    // cacheTime: 0, // (5 min default)
    refetchOnWindowFocus: false,
    retry: false,
    queryKey: ['pears'],
    queryFn: getPearsData,
  });


  const onSubmit = async (values) => {
    setPageMessage(null);
    setPageErrorMessage(null);
    setSubmitting(true);

    const input = Object.keys(values).reduce((acc, key) => {
      const value = values[key];
      switch (key) {
        case 'lesson':
          if (value?.id) {
            acc[`${key}ID`] = value?.id;
          }
          break;
        case 'start':
        case 'end':
          acc[key] = moment(value).toISOString(true);
          break;
        case 'donations_total':
          acc[key] = value ? String(value).replaceAll(/[^0-9.]+/g, '') : 0;
          break;
        case 'participant_needs_other':
          if (values.participant_needs.includes('other')) {
            acc[key] = value?.id || value;
          } else {
            acc[key] = null;
          }
          break;
        case 'as_planned_detail':
        case 'participant_needs':
        case 'participant_actions':
        case 'delivery_method':
        case 'delivery_location':
        case 'delivery_url':
        case 'comments':
        case 'recipes':
          acc[key] = value?.id || value;
          break;
        case 'delivery_language':
        case 'material_language':
          acc[key] = (value || []);
          break;
        case 'as_planned':
        case 'demonstration':
        case 'donations_received':
          acc[key] = value === 'true';
          break;
        default: break;
      }
      return acc;
    }, {
      sessionID: session.id,
      updated_by: userRecord.email,
      created_by: session?.completed?.created_by || userRecord.email,
    });

    if (ability.cannot('edit', subject(Subjects.SESSION, { ...input, event: session.event }))) {
      setSubmitting(false);
      setPageErrorMessage('You do not have permission to edit this session.');
      scrollToAlert();
      return false;
    }

    try {
      let completedResult;
      const saveAndComplete = values.save === 'complete';
      if (session.completedID) {
        // we are updating, not creating
        input.id = session.completedID;
        completedResult = await graphQuery(updateSessionCompleted, { input });
      } else {
        completedResult = await graphQuery(createSessionCompleted, { input });
      }
      const completedID = completedResult?.id;
      if (!completedID) {
        throw new Error('Error creating completed session.');
      }
      if (!session.completedID || saveAndComplete) {
        // Complete session if it was selected, and/or update the session with the completed ID
        const updateInput = { id: sessionID, completedID };
        if (saveAndComplete) {
          updateInput.status = 'COMPLETED';
          updateInput.completed_by = userRecord.email;
          updateInput.completedAt = moment().toISOString(true);
        }
        await graphQuery(updateSession, { input: updateInput });
        await invalidateQueries('listEvents');
      }
      setSubmitting(false);
      navigate(`/events/${eventID}/${sessionID}`, { state: {
        message: saveAndComplete ? 'Session Completed' : 'Complete session saved',
      }});
    } catch (error) {
      console.log('Error completing session: ', error);
      setSubmitting(false);
      setPageErrorMessage('Error completing session. Please try again.');
      scrollToAlert();
    }
  };

  useEffect(() => {
    if (isError || pearsIsError) {
      console.log('[UpcomingSessions] error fetching session:', error || pearsError);
      setTitle('Error Accessing Page');
    }
  }, [error, isError, setTitle]);

  useEffect(() => {
    if (session) {
      setBreadcrumbValue(':event', session.event);
      setBreadcrumbValue(':session', session);
    }
    // eslint-disable-next-line
  }, [session]);

  const formReady = useMemo(() => {
    return !loading && !isError && !pearsLoading && !pearsIsError && !boothQuery.isLoading && !boothQuery.isError && session && pearsData && boothQuery.data;
  }, [loading, isError, pearsLoading, pearsIsError, session, pearsData]);

  return (
    <Grid container gap={3} wrap={'nowrap'} columns={10}>
      <Grid xs={10}>
        { !loading && !pearsLoading && session === false && (
          <>You do not have access, or the event was not found.</>
        )}
        { (loading || pearsLoading) && (
          <Box width="100%" textAlign="center">
            <CircularProgress />
          </Box>
        )}
        { !!formReady && (
          <CompleteSessionForm
            session={session}
            form={form}
            pearsData={pearsData}
            boothTopics={boothQuery.data}
            submitting={submitting}
            onSubmit={onSubmit}
            valuesSetState={valuesSetState}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default CompleteSession;
