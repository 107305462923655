import React, { useRef, useState } from 'react';
import { TextareaAutosizeElement } from 'react-hook-form-mui';
import { getWordCount } from '../../lib/utils';
import { useMemo } from 'react';

const TextAreaLimiter = ({ wordLimit, getHelperText, ...props }) => {
  const [wordCount, setWordCount] = useState(0);
  const keyStateRef= useRef({
    cmd: false,
    alt: false,
    ctl: false,
  });

  const isKeyCombo = () => Object.values(keyStateRef.current).some((isDown) => isDown);

  const wordLimiter = (e) => {
    if (e.key.length) {
      const isText = e.key.length === 1 && !isKeyCombo();
      let value = e.target.value + (isText ? e.key : '');
      let words = getWordCount(value);
      if (isText && words > wordLimit) {
        e.preventDefault();
        value = e.target.value;
        words = getWordCount(value);
      }
      // In the event the text is changed after control key usage, we will yield in order for the text to update
      // with the value of key down.
      if (!isText) {
        setTimeout(() => {
          value = e.target.value;
          words = getWordCount(value);
          if (words !== wordCount) {
            setWordCount(words);
          }
        }, 0);
      } else if (words !== wordCount) {
        setWordCount(words);
      }
    }
  };

  const handleKey = (down) => (e) => {
    const keyState = keyStateRef.current;
    if (e.key === 'Meta') {
      keyState.cmd = down;
    }
    if (e.key === 'Alt') {
      keyState.alt = down;
    }
    if (e.key === 'Control') {
      keyState.ctl = down;
    }

    if (down) {
      wordLimiter(e);
    }
  };

  const helperText = useMemo(() => getHelperText(wordCount), [wordCount]);

  return (
    <TextareaAutosizeElement
      // onChange={handleTextArea('plannedDetail')}
      helperText={helperText}
      onKeyDown={handleKey(true)}
      onKeyUp={handleKey(false)}
      variant={'standard'}
      validation={{
        validate: {
          length: (value) => {
            const valid = getWordCount(value) <= wordLimit;
            return valid || `Must be ${wordLimit} words or less`;
          },
        }
      }}
      {...props}
    />
  );
};

export default TextAreaLimiter;
