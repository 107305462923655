import { Link as RouterLink } from 'react-router-dom';
import { Link, TableCell, TableContainer } from '@mui/material';
import { NILL } from '../../../../lib/constants';
import { defaultComparator } from '../../../../lib/utils';
import MuiTable from '../../../common/MuiTable';
import {getEventDates, getEventStatus, getEventStatusIcon, getStatusOrd} from "../eventFunctions";

const headCells = [
  {
    id: 'event_name',
    label: 'Event Name',
  },
  {
    id: 'curriculum_name',
    label: 'Curriculum / Booth',
  },
  {
    id: 'sessions',
    label: 'Completed Sessions',
  },
  {
    id: 'session_dates',
    label: 'Session Dates',
  },
  {
    id: 'created_by',
    label: 'Created By',
  },
  {
    id: 'status',
    label: 'Status',
  },
];

/**
 * Returns a comparison function.
 *
 * @param order
 * @param orderBy
 */
const getComparator = (order, orderBy) => {
  const ord = order === 'desc' ? -1 : 1;
  switch (orderBy) {
    case 'session_dates':
      return (a, b) => {
        if (a === b) return 0;
        if (!a?.dates?.start) return -1 * ord;
        if (!b?.dates?.start) return 1 * ord;
        return a.dates.start.diff(b.dates.start) * ord;
      };
    case 'sessions':
      const getTotal = (e) =>
        !e?.counts ? 0 : e?.counts?.COMPLETED / (e?.counts?.total || 1);
      return (a, b) => {
        let diff = getTotal(a) - getTotal(b);
        if (diff === 0) {
          diff = (a?.counts?.total || 0) - (b?.counts?.total || 0);
        }
        return diff * ord;
      };
    case 'status':
      return (a, b) => (getStatusOrd(a) - getStatusOrd(b)) * ord;
    case 'event_name':
    case 'curriculum_name':
    case 'created_by':
      return (a, b) => {
        const valueA = a[orderBy] || '';
        const valueB = b[orderBy] || '';
        return valueA.localeCompare(valueB) * ord;
      };
    default:
      return defaultComparator;
  }
};

const EventsTable = (props) => {
  const { events } = props;

  return (
    <TableContainer sx={{ marginTop: '32px' }}>
      <MuiTable
        defaultOrderBy="event_name"
        headCells={headCells}
        getComparator={getComparator}
        data={events}
      >
        {(row) => (
          <>
            <TableCell component="th" scope="row" data-cy="name-cell">
              <Link to={`/events/${row.id}`} component={RouterLink}>
                {row.event_name}
              </Link>
            </TableCell>
            {/** curriculum, completed sessions, session dates, created by, status **/}
            <TableCell align="left" data-cy="curriculum-cell">{row.curriculum_name || NILL}</TableCell>
            <TableCell align="left" data-cy="session-counts-cell">
              {row.counts
                ? `${row.counts.COMPLETED} / ${row.counts.total}`
                : NILL}
            </TableCell>
            <TableCell align="left" data-cy="date-cell">
              {getEventDates(row)}
            </TableCell>
            <TableCell align="left" data-cy="create-by-cell">{row.created_by_names}</TableCell>
            <TableCell align="left" data-cy="status-cell">{getEventStatusIcon(row)}</TableCell>
          </>
        )}
      </MuiTable>
    </TableContainer>
  );
};

export default EventsTable;
