import React, { useContext, useEffect } from 'react';
import { useAbility } from '@casl/react';
import SectionBar from '../common/Layout/SectionBar';
import { Box } from '@mui/system';
import Grid from '@mui/material/Unstable_Grid2';
import { CircularProgress, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { PageContext } from '../../lib/contexts/pageContext';
import Sidebar from '../common/Layout/Sidebar';
import UserContext from '../../lib/contexts/userContext';
import Alerts from '../common/Alerts';
import { AbilityContext, Subjects } from '../../lib/permissions';
import BreadcrumbTrail from '../common/BreadcrumbTrail';

const Page = (props) => {
  const {
    pathData,
    setPageMessages,
    setPageErrorMessages,
    pageLoading,
    authorized,
    setAuthorized,
    clearBreadcrumbValues,
  } = useContext(PageContext);

  const { authenticating, userState: { userRole, userRecord } } = useContext(UserContext);
  const ability = useAbility(AbilityContext);

  const {
    title,
    sectionName,
    pageName,
    pageId = null,
    hideNav,
    navPhoto,
    loaderOffset = true,
    breadcrumbs,
    showAlerts = true,
    contentGrid = true,
    children,
    requireAuth,
  } = {
    ...pathData,
    ...props,
  };

  const location = useLocation();

  useEffect(() => {
    const initialAuth = (
      !pageId
      || !requireAuth
      || (Subjects[`PAGE_${pageId}`] && ability.can('view', Subjects[`PAGE_${pageId}`]))
    );
    // console.log('initial auth', initialAuth, 'pageId', pageId, 'requireAuth', requireAuth, 'ability', (Subjects[`PAGE_${pageId}`] && ability.can('view', Subjects[`PAGE_${pageId}`])));
    setAuthorized(initialAuth);
  }, [ability, authorized, pageId, requireAuth, setAuthorized, userRecord]);

  useEffect(() => {
    setPageMessages(null);
    setPageErrorMessages(null);
    if (location.state?.error) {
      setPageErrorMessages(location.state.error);
      window.history.replaceState({ ...location, state: { error: null } }, '')
    } else {
      setPageErrorMessages(null);
    }
    if (location.state?.message) {
      setPageMessages(location.state.message);
      window.history.replaceState({ ...location, state: { message: null } }, '')
    } else {
      setPageMessages(null);
    }
  }, [
    location.pathname,
    location.state,
    setPageErrorMessages,
    setPageMessages
  ]);

  // Clear breadcrumb placeholders when each page unmounts
  useEffect(() => {
    // All work here is done in the cleanup function.
    return clearBreadcrumbValues;
    // eslint-disable-next-line
  }, []);

  const pageLoader = ((requireAuth && (authenticating || !userRole || !userRecord)) || pageLoading);

  // if (pageLoader) {
  //   console.log('[PAGE LOADER]', 'requireAuth', requireAuth, 'authenticating', authenticating, 'userRole', userRole, 'pageLoading', pageLoading);
  // }

  return (
    <Box className="mui">
      <SectionBar
        sectionName={sectionName}
        pageName={pageName}
      />
      {contentGrid && (
        <Box className={'outer-grid'} sx={{ padding: '32px 24px' }}>
          <Grid container gap={3} columns={12} wrap={'nowrap'}>
            <Box
              component={Grid}
              md={3}
              lg={2}
              display={{ xs: 'none', sm: 'none', md: 'block' }}
              className={'sidebar-container'}
            >
              { !hideNav && (
                <Sidebar photo={navPhoto} />
              ) }
            </Box>
            <Grid className={'page-content'} xs={12} sm={12} md={9} lg={10}>
              <BreadcrumbTrail breadcrumbs={breadcrumbs} />
              <Typography variant="h4" className={'page-title'}>{title}</Typography>

              {showAlerts && (
                <Alerts />
              )}

              {/* NOTE: Columns change depending on whether, or not we're showing the nav. This affects the page content components. */}
              { pageLoader && (
                <Grid container justifyContent="center" spacing={3}>
                  <Grid xs={loaderOffset ? 10 : 12} textAlign="center">
                    <CircularProgress/>
                  </Grid>
                  { loaderOffset && (
                    <Box
                      component={Grid}
                      xs={0} sm={0} md={3} lg={2}
                      display={{ xs: 'none', sm: 'none', md: 'block' }}
                    />
                  )}
                </Grid>
              )}
              { !pageLoader && (!requireAuth || (!authenticating && userRole && authorized)) ? (
                <>
                  { children }
                </>
              ) : !pageLoader && (!authenticating && !authorized) && (
                <>
                  You do not have access to this page.
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
      {!contentGrid && children}
    </Box>
  );
};

export default Page;
