export const cancelSession = /* GraphQL */ `
  mutation CancelSession(
    $input: UpdateSessionInput!
  ) {
    updateSession(input: $input) {
      id
      session_name
      status
      eventID
      lessonID
      lesson {
        id
        curriculumID
        curriculum {
          id
          curriculum_name
          createdAt
          updatedAt
          owner
          __typename
        }
        lesson_name
        booth_topic
        snap_ed
        efnep
        cphp
        points
        description
        display_order
        audience
        createdAt
        updatedAt
        owner
        __typename
      }
      completedID
      completed {
        id
        lessonID
        lesson {
          id
          curriculumID
          curriculum {
            id
            curriculum_name
            createdAt
            updatedAt
            owner
            __typename
          }
          lesson_name
          booth_topic
          snap_ed
          efnep
          cphp
          points
          description
          display_order
          audience
          createdAt
          updatedAt
          owner
          __typename
        }
        sessionID
        start
        end
        participant_needs
        participant_needs_other
        participant_actions
        delivery_method
        delivery_url
        delivery_language
        material_language
        delivery_location
        demonstration
        recipes
        donations_received
        donations_total
        as_planned
        as_planned_detail
        comments
        created_by
        updated_by
        createdAt
        updatedAt
        __typename
      }
      start
      end
      delivery_method
      delivery_url
      delivery_language
      material_language
      delivery_location
      other_details
      has_wifi
      demonstration
      recipes
      short_code
      qr_code
      cancel_reason
      canceled_by
      completed_by
      created_by
      updated_by
      completedAt
      canceledAt
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const completeEvent = /* GraphQL */ `
  mutation CompleteEvent(
    $input: UpdateEventInput!
  ) {
    updateEvent(input: $input) {
      id
      event_name
      event_type
      event_partner
      status
      internal_only
      program_areas
      curriculumID
      curriculum {
        id
        curriculum_name
        createdAt
        updatedAt
        owner
        __typename
      }
      additionalID
      additional {
        id
        additional_name
        snap_ed
        efnep
        cphp
        points
        description
        audience
        display_order
        createdAt
        updatedAt
        owner
        __typename
      }
      participant_needs
      participant_needs_other
      main_office
      special_project
      points
      sessions {
        items {
          id
          session_name
          status
          eventID
          lessonID
          lesson {
            id
            curriculumID
            curriculum {
              id
              curriculum_name
              createdAt
              updatedAt
              owner
              __typename
            }
            lesson_name
            booth_topic
            snap_ed
            efnep
            cphp
            points
            description
            display_order
            audience
            createdAt
            updatedAt
            owner
            __typename
          }
          start
          end
          delivery_method
          delivery_url
          delivery_language
          material_language
          delivery_location
          other_details
          has_wifi
          demonstration
          recipes
          short_code
          qr_code
          participant_count
          cancel_reason
          canceled_by
          created_by
          updated_by
          canceledAt
          createdAt
          updatedAt
          __typename
        }
      }
      created_by
      updated_by
      completed_by
      createdAt
      updatedAt
      completedAt
      __typename
    }
  }
`;

export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        event_name
        event_type
        event_partner
        status
        internal_only
        program_areas
        curriculumID
        curriculum {
          id
          curriculum_name
          createdAt
          updatedAt
          owner
          __typename
        }
        additionalID
        additional {
          id
          additional_name
          snap_ed
          efnep
          cphp
          points
          description
          audience
          display_order
          createdAt
          updatedAt
          owner
          __typename
        }
        participant_needs
        participant_needs_other
        main_office
        special_project
        points
        sessions {
          items {
            id
            session_name
            status
            eventID
            lessonID
            lesson {
              id
              curriculumID
              curriculum {
                id
                curriculum_name
                createdAt
                updatedAt
                owner
                __typename
              }
              lesson_name
              booth_topic
              snap_ed
              efnep
              cphp
              points
              description
              display_order
              audience
              createdAt
              updatedAt
              owner
              __typename
            }
            completedID
            completed {
              id
              lessonID
              lesson {
                id
                curriculumID
                curriculum {
                  id
                  curriculum_name
                  createdAt
                  updatedAt
                  owner
                  __typename
                }
                lesson_name
                booth_topic
                snap_ed
                efnep
                cphp
                points
                description
                display_order
                audience
                createdAt
                updatedAt
                owner
                __typename
              }
              sessionID
              start
              end
              participant_needs
              participant_needs_other
              participant_actions
              delivery_method
              delivery_url
              delivery_language
              material_language
              delivery_location
              demonstration
              recipes
              donations_received
              donations_total
              as_planned
              as_planned_detail
              comments
              created_by
              updated_by
              createdAt
              updatedAt
              __typename
            }
            start
            end
            delivery_method
            delivery_url
            delivery_language
            material_language
            delivery_location
            other_details
            has_wifi
            demonstration
            recipes
            short_code
            qr_code
            participant_count
            cancel_reason
            canceled_by
            completed_by
            created_by
            updated_by
            canceledAt
            completedAt
            createdAt
            updatedAt
            __typename
          }
        }
        created_by
        updated_by
        completed_by
        createdAt
        updatedAt
        completedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listEventsSessions = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $secondaryFilter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
    $nextSessionToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        event_name
        event_type
        event_partner
        status
        internal_only
        program_areas
        curriculumID
        curriculum {
          id
          curriculum_name
          additional {
            items {
              id
              curriculumID
              curriculumAdditionalID
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          lessons {
            items {
              id
              curriculumID
              lesson_name
              booth_topic
              snap_ed
              efnep
              cphp
              points
              description
              display_order
              audience
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        additionalID
        additional {
          id
          additional_name
          snap_ed
          efnep
          cphp
          points
          description
          audience
          display_order
          createdAt
          updatedAt
          owner
          __typename
        }
        participant_needs
        participant_needs_other
        main_office
        special_project
        points
        sessions(filter: $secondaryFilter, limit: $limit, nextToken: $nextSessionToken) {
          items {
            id
            session_name
            status
            eventID
            event {
              id
              event_name
              event_type
              event_partner
              status
              internal_only
              program_areas
              curriculumID
              additionalID
              participant_needs
              participant_needs_other
              main_office
              special_project
              points
              created_by
              updated_by
              createdAt
              updatedAt
              __typename
            }
            lessonID
            lesson {
              id
              curriculumID
              lesson_name
              booth_topic
              snap_ed
              efnep
              cphp
              points
              description
              display_order
              audience
              createdAt
              updatedAt
              owner
              __typename
            }
            completedID
            completed {
              id
              lessonID
              lesson {
                id
                curriculumID
                curriculum {
                  id
                  curriculum_name
                  createdAt
                  updatedAt
                  owner
                  __typename
                }
                lesson_name
                booth_topic
                snap_ed
                efnep
                cphp
                points
                description
                display_order
                audience
                createdAt
                updatedAt
                owner
                __typename
              }
              sessionID
              start
              end
              participant_needs
              participant_needs_other
              participant_actions
              delivery_method
              delivery_url
              delivery_language
              material_language
              delivery_location
              demonstration
              recipes
              donations_received
              donations_total
              as_planned
              as_planned_detail
              comments
              created_by
              updated_by
              createdAt
              updatedAt
              __typename
            }
            start
            end
            delivery_method
            delivery_url
            delivery_language
            material_language
            delivery_location
            other_details
            has_wifi
            demonstration
            recipes
            short_code
            qr_code
            participant_count
            cancel_reason
            canceled_by
            completed_by
            created_by
            updated_by
            canceledAt
            completedAt
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_by
        updated_by
        completed_by
        createdAt
        updatedAt
        completedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listSessions = /* GraphQL */ `
  query ListSessions(
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        session_name
        status
        eventID
        event {
          id
          event_name
          event_type
          event_partner
          status
          internal_only
          program_areas
          curriculumID
          curriculum {
            id
            curriculum_name
            lessons {
              items {
                id
                curriculumID
                lesson_name
                booth_topic
                snap_ed
                efnep
                cphp
                points
                description
                display_order
                audience
                createdAt
                updatedAt
                owner
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          additionalID
          additional {
            id
            additional_name
            snap_ed
            efnep
            cphp
            points
            description
            audience
            display_order
            createdAt
            updatedAt
            owner
            __typename
          }
          participant_needs
          participant_needs_other
          main_office
          special_project
          points
          sessions {
            items {
              id
              status
              eventID
              lessonID
              completedID
              completed {
                id
                lessonID
                lesson {
                  id
                  curriculumID
                  curriculum {
                    id
                    curriculum_name
                    createdAt
                    updatedAt
                    owner
                    __typename
                  }
                  lesson_name
                  booth_topic
                  snap_ed
                  efnep
                  cphp
                  points
                  description
                  display_order
                  audience
                  createdAt
                  updatedAt
                  owner
                  __typename
                }
                sessionID
                start
                end
                participant_needs
                participant_needs_other
                participant_actions
                delivery_method
                delivery_url
                delivery_language
                material_language
                delivery_location
                demonstration
                recipes
                donations_received
                donations_total
                as_planned
                as_planned_detail
                comments
                created_by
                updated_by
                createdAt
                updatedAt
                __typename
              }
              start
              end
              delivery_method
              delivery_url
              delivery_language
              material_language
              delivery_location
              other_details
              has_wifi
              demonstration
              recipes
              short_code
              qr_code
              created_by
              updated_by
              createdAt
              updatedAt
            }
            nextToken
            __typename
          }
          created_by
          updated_by
          createdAt
          updatedAt
          __typename
        }
        lessonID
        lesson {
          id
          curriculumID
          curriculum {
            id
            curriculum_name
            createdAt
            updatedAt
            owner
            __typename
          }
          lesson_name
          booth_topic
          snap_ed
          efnep
          cphp
          points
          description
          display_order
          audience
          createdAt
          updatedAt
          owner
          __typename
        }
        start
        end
        delivery_method
        delivery_url
        delivery_language
        material_language
        delivery_location
        other_details
        has_wifi
        demonstration
        recipes
        short_code
        qr_code
        participant_count
        cancel_reason
        canceled_by
        created_by
        updated_by
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listEventsWithLessons = /* GraphQL */ `
  query listEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        event_name
        event_type
        event_partner
        internal_only
        program_areas
        curriculumID
        curriculum {
          id
          curriculum_name
          lessons {
            items {
              id
              curriculumID
              lesson_name
              booth_topic
              snap_ed
              efnep
              cphp
              points
              description
              display_order
              audience
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        additionalID
        additional {
          id
          additional_name
          snap_ed
          efnep
          cphp
          points
          description
          audience
          display_order
          createdAt
          updatedAt
          owner
          __typename
        }
        participant_needs
        participant_needs_other
        main_office
        special_project
        points
        sessions {
          items {
            id
            session_name
            status
            eventID
            lessonID
            lesson {
              id
              curriculumID
              lesson_name
              booth_topic
              snap_ed
              efnep
              cphp
              points
              description
              display_order
              audience
              createdAt
              updatedAt
              owner
              __typename
            }
            completedID
            completed {
              id
              lessonID
              lesson {
                id
                curriculumID
                curriculum {
                  id
                  curriculum_name
                  createdAt
                  updatedAt
                  owner
                  __typename
                }
                lesson_name
                booth_topic
                snap_ed
                efnep
                cphp
                points
                description
                display_order
                audience
                createdAt
                updatedAt
                owner
                __typename
              }
              sessionID
              start
              end
              participant_needs
              participant_needs_other
              participant_actions
              delivery_method
              delivery_url
              delivery_language
              material_language
              delivery_location
              demonstration
              recipes
              donations_received
              donations_total
              as_planned
              as_planned_detail
              comments
              created_by
              updated_by
              createdAt
              updatedAt
              __typename
            }
            start,
            end,
            delivery_method
            delivery_url
            delivery_language
            material_language
            delivery_location
            other_details
            has_wifi
            demonstration
            recipes
            qr_code
            short_code
            participant_count
            cancel_reason
            canceled_by
            updated_by
            created_by
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        created_by
        updated_by
        createdAt
        updatedAt
        __typename
      }
    }
  }
`;
