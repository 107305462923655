import React, { useContext } from 'react';
import { Breadcrumbs, Link, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { PageContext } from '../../lib/contexts/pageContext';
import { truncateString } from '../../lib/utils';
import { getSessionName } from '../Pages/Events/Sessions/sessionFunctions';

// This renders the breadcrumb trail. There are 3 ways to define a breadcrumb:
// 1. A common route like 'events' - This is translated into a link to that page
// 2. A placeholder, like ':event' - This will render a link, only if the related value has been set in the context
// 3. A string not matching a route, e.g., 'Add Session'. This will render as a string with no link.
//
// Example: ['events', ':event", 'Add Session'] will render "Home > Events > Event Name > Add Session"

const breadcrumbRoutes = {
  events: {
    label: 'Events',
    path: '/events',
  },
  pastSessions: {
    label: 'Sessions - Past',
    path: '/sessions/past',
  },
  upcomingSessions: {
    label: 'Sessions - Upcoming',
    path: '/sessions/upcoming',
  },
  ':event': (e) => ({
    label: truncateString(e.event_name, 30),
    path: `/events/${e.id}`,
  }),
  ':session': (session) => ({
    label: truncateString(getSessionName({
      session,
      showNumbers: false,
      dateFallback: true,
      useNill: false,
    }), 30),
    path: `/events/${session.eventID}/${session.id}`,
  }),
  login: {
    label: 'Log In',
    path: '/login',
  }
};

const BreadcrumbTrail = ({ breadcrumbs }) => {
  const { breadcrumbValues } = useContext(PageContext);

  if (!breadcrumbs) {
    breadcrumbs = [];
  }
  const crumbs = breadcrumbs.map(c => {
    const route = breadcrumbRoutes[c];

    // If not listed in routes above, assume it's just plain text
    if (!route) {
      return {
        label: c,
      }
    }

    // Breadcrumbs beginning with : (e.g., ':event') are placeholders
    // and build their breadcrumbs from data stored in the context.
    if (c[0] === ':') {
      const value = breadcrumbValues[c];
      // look for value in context
      // if present, add to the crumbs
      if (value) {
        return route(value);
      }
      // If value is not set, we're probably waiting for it to load from the API, so
      // return a placeholder.
      return { label: '...' };
    }

    // If it doesn't use a placeholder, that means it's just a common route like 'Events'
    return route;
  });

  const theme = useTheme();

  let done = false;

  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb" marginBottom="16px" data-cy="breadcrumbs">
      <span>Home</span>
      {crumbs.map((crumb, i) => {
        // Stop rendering if we reach a crumb that requires a value that isn't available yet.
        // This reduces layout thrashing.
        if (done) return null;
        if (crumb.label === '...') {
          done = true;
          return null;
        }

        const last = i === crumbs.length - 1;
        if (crumb.path) {
          return (
            <Link
              key={`crumb-${i}`}
              underline={last ? 'hover' : 'always'}
              color={last ? 'primary' : 'secondary'}
              to={crumb.path}
              sx={last ? {} : {
                color: theme.palette.ilstorm.main,
                textDecorationColor: theme.palette.ilstorm.main,
              }}
              component={RouterLink}
            >
              {crumb.label}
            </Link>
          );
        }

        return (<span key={`crumb-${i}`}>{crumb.label}</span>);

      })}
    </Breadcrumbs>
  );
};

export default BreadcrumbTrail;
