import {useContext, useEffect, useMemo, useState} from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { subject } from '@casl/ability';
import { useAbility } from '@casl/react';
import Grid from '@mui/material/Unstable_Grid2';
import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';
import {
  scrollToAlert,
  sortDisplayOrder,
} from '../../../../../lib/utils';
import { PageContext } from '../../../../../lib/contexts/pageContext';
import UserContext from '../../../../../lib/contexts/userContext';
import moment from '../../../../../lib/moment';
import { AbilityContext, Subjects } from '../../../../../lib/permissions';
import SessionForm from '../SessionForm';
import { updateSession } from '../../../../../graphql/mutations';
import { getSessionName } from '../sessionFunctions';
import useInvalidateQuery from '../../../../common/hooks/useInvalidateQuery';
import useEventSessionQuery from "../../../../common/hooks/useEventSessionQuery";

const EditSession = ({ setTitle }) => {
  const [submitting, setSubmitting] = useState(false);
  const valuesSetState = useState(null);

  const { setBreadcrumbValue, setPageMessage, setPageErrorMessage } = useContext(PageContext);
  const { userState: { userRecord } } = useContext(UserContext);

  const ability = useAbility(AbilityContext);

  const invalidateQueries = useInvalidateQuery();

  const navigate = useNavigate();

  const { eventID, sessionID } = useParams();

  const form = useForm({
    mode: 'onSubmit',
    shouldFocusError: false,
    defaultValues: {
      session_name: null,
      lesson: null,
      start: null,
      end: null,
      has_wifi: null,
      delivery_method: [],
      delivery_url: null,
      delivery_location: null,
      other_details: null,
      recipes: null,
      delivery_language: [],
      material_language: [],
      demonstration: null,
    }
  });

  const { data: event, loading } = useEventSessionQuery({
    permissions: {
      action: 'edit',
      subject: Subjects.EVENT,
      id: eventID,
    },
    selectFn: (events) => {
      if (!events || events.length === 0) {
        return null;
      }
      if (events.length > 1) {
        console.error('Returned more than one event!', events);
      }
      return events[0];
    },
  });
  const session = useMemo(() => {
    if (!event) {
      return null;
    }
    const sessionData = event.sessions.items.find((s) => s.id === sessionID);
    sessionData.name = getSessionName({
      session: sessionData,
      showNumbers: false,
      dateFallback: true,
      useNill: false,
    });
    return sessionData;
  }, [event, sessionID]);

  useEffect(() => {
    if (session) {
      setBreadcrumbValue(':event', event);
      setBreadcrumbValue(':session', session);

      if (event.event_type === 'CLASS' && event.curriculum?.lessons) {
        sortDisplayOrder(event.curriculum.lessons.items, 'lesson_name');
      }
    }
    // eslint-disable-next-line
  }, [event, session]);

  /**
   * Submit handler.
   *
   * @param values
   */
  const onSubmit = async (values) => {
    setPageMessage(null);
    setPageErrorMessage(null);
    setSubmitting(true);

    const input = Object.keys(values).reduce((acc, key) => {
      const value = values[key];
      switch (key) {
        case 'lesson':
          if (value?.id) {
            acc[`${key}ID`] = value?.id;
          }
          if (event.event_type === 'BOOTH') {
            acc.session_name = value?.label;
          }
          break;
        case 'start':
        case 'end':
          acc[key] = moment(value).toISOString(true);
          break;
        case 'session_name':
        case 'has_wifi':
        case 'delivery_method':
        case 'delivery_url':
        case 'delivery_location':
        case 'other_details':
        case 'recipes':
          acc[key] = value?.id || value;
          break;
        case 'delivery_language':
        case 'material_language':
          acc[key] = (value || []);
          break;
        case 'demonstration':
          acc[key] = value === 'true';
          break;
        default: break;
      }
      return acc;
    }, {
      id: session.id,
      eventID: event.id,
      completedID: session?.completedID,
      updated_by: userRecord.email,
    });

    if (ability.cannot('edit', subject(Subjects.SESSION, { ...session, ...input }))) {
      setSubmitting(false);
      setPageErrorMessage('You do not have permission to edit this session.');
      scrollToAlert();
      return false;
    }

    try {
      await API.graphql({
        ...graphqlOperation(updateSession, { input }),
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      await invalidateQueries('listEvents');
      setSubmitting(false);
      navigate(`/events/${eventID}/${sessionID}`, { state: { message: 'Session updated' }});
    } catch (error) {
      console.log('Error updating session: ', error);
      setSubmitting(false);
      setPageErrorMessage('Error updating session. Please try again.');
      scrollToAlert();
    }
  };
  // if (!formData) {
  //   console.log('[EditSession][Loader]', formData);
  // }

  return (
    <Grid container gap={3} wrap={'nowrap'} columns={10}>
      <Grid xs={10}>
        {loading && (
          <Box width="100%" textAlign="center">
            <CircularProgress />
          </Box>
        )}

        {!loading && !event && (
          <>You do not have access, or the event was not found.</>
        )}

        {!loading && event && session && (
          <SessionForm
            form={form}
            session={session}
            event={event}
            submitting={submitting}
            onSubmit={onSubmit}
            valuesSetState={valuesSetState}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default EditSession;
