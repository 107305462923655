import UserContext from '../../../lib/contexts/userContext';
import usePermissionFilter from './usePermissionFilter';
import { getStaffUsers, graphQuery } from '../../../lib/api';
import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect, useMemo } from 'react';
import { listEventsSessions } from '../../Pages/Events/eventQueries';
import { getSessionTotals } from '../../Pages/Events/eventFunctions';

/**
 * React Query wrapper for the GraphQL ListEvents query (focussed on sessions). Also handles the filter and
 * authentication dependencies.
 *
 * @param permissions The subject and action used to determine permissions and GraphQL main filter.
 * @param secondaryQueryFilter GraphQL filter applied to the second filter parameter (if defined in the query string).
 * @param selectFn A useQuery select function that will perform operations on the data before returning it as data.
 * @returns {{isError: boolean, data: (*|[]), loading: boolean, error: unknown}}
 */
const useEventSessionQuery = ({
  permissions,
  secondaryQueryFilter = { id: { ne: null } }, // an empty expression throws an error
  selectFn = (data) => data,
}) => {
  const { authenticated } = useContext(UserContext);

  const { filter: mainFilter } = usePermissionFilter(permissions);

  const {
    data: staffUsers,
    isLoading: isStaffLoading,
    isRefetching: isStaffRefetching,
    isError: isStaffError,
    error: staffError,
  } = useQuery({
    enabled: authenticated,
    structuralSharing: false,
    staleTime: 5 * 60 * 1000,
    // cacheTime: 0, // (5 min default)
    refetchOnWindowFocus: false,
    retry: false,
    queryKey: ['staffEmails'],
    queryFn: getStaffUsers,
  });

  const {
    data,
    error,
    refetch,
    isError,
    isLoading,
    // isLoaded,
    // isFetching,
    // isFetched,
    isRefetching,
    // isRefetched,
  } = useQuery({
    enabled: authenticated && !!mainFilter,
    structuralSharing: false,
    staleTime: 3 * 60 * 1000,
    cacheTime: 0, // (5 min default)
    refetchOnWindowFocus: false,
    retry: false,
    queryKey: ['listEvents', permissions, secondaryQueryFilter],
    queryFn: () => graphQuery(listEventsSessions, { filter: mainFilter, secondaryFilter: secondaryQueryFilter }),
  });

  const events = useMemo(() => {
    if (staffUsers?.length > 0 && data?.length > 0) {
      // Collect staff emails, retrieve staff names, and populate back into event and session instances
      const staffEmails = new Set();
      const addEmails = (obj) => {
        ['created_by', 'updated_by', 'canceled_by'].forEach((attr) => {
          if (obj[attr]) {
            staffEmails.add(obj[attr]);
          }
        });
      };
      data.forEach((event) => {
        addEmails(event);
        event.sessions?.items?.forEach(addEmails);
      });

      const getUser = (email) => staffUsers.find((u) => u.email === email);
      const staffNames = Array.from(staffEmails).reduce((acc, email) => {
        const user = getUser(email);
        acc[email] = user?.name || email;
        return acc;
      }, {});

      /**
       * Populates the names of the people who created/edited/etc. the object.
       * This makes properties like `event.created_by_names` work.
       * @param obj An Event or Session object
       */
      const addNames = (obj) => {
        ['created_by', 'updated_by', 'canceled_by', 'completed_by'].forEach((attr) => {
          if (obj[attr]) {
            obj[`${attr}_names`] = staffNames[obj[attr]];
          }
        })
      };

      const items = data.map((event) => {
        addNames(event);
        event.sessions?.items?.forEach(addNames);
        const totals = getSessionTotals(event.sessions?.items);
        return { ...event, ...totals };
      });

      return selectFn(items);
    }

    return selectFn([]);
    // eslint-disable-next-line
  }, [staffUsers, data]);

  const loading = (isLoading) || (isStaffLoading);
  const refetching = (isRefetching) || (isStaffRefetching);

  return {
    data: events,
    loading,
    refetch,
    refetching,
    error: error || staffError,
    isError: isError || isStaffError,
  }
};

export default useEventSessionQuery;
