import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from './Home/Home';
import SignUp from './SignUp';
import Login from './Login/Login';
import OldCurrentEvent from './Events/OldCurrentEvent';
import ActivitiesPage from './Member/Activities';
import CreateEventPage from './Events/CreateEvent';
import ProfilePage from './Member/Profile';
import CheckIn from './Checkin';
import ChangePassword from './ChangePassword/ChangePassword';
import RequireAuth from '../common/RequireAuth';
import LoginForm from './Login/LoginForm';
import ResetPassword from './Login/ResetPassword';
import Verify from './Login/Verify';
import { ViewEventsPage } from './Events/ViewEvents';
import EventDetailsPage from './Events/EventDetails';
import EditEventPage from './Events/EditEvent';
import CreateSessionPage from './Events/Sessions/CreateSession';
import SessionDetailsPage from './Events/Sessions/SessionDetails';
import EditSessionPage from './Events/Sessions/EditSession';
import SSO from './Login/SSO';
import PastSessionsPage from './Events/Sessions/Past';
import UpcomingSessionsPage from './Events/Sessions/Upcoming';
import CompleteSessionPage from './Events/Sessions/CompleteSession';

const PageRoutes = (props) => {
  return (
    <Routes
      sectionName={props.sectionName}
    >
      <Route path="/" element={<HomePage />}/>

      { /** UTIL **/ }
      <Route path="/signup" element={<SignUp />}/>
      <Route path="/login/sso" element={<SSO />}/>
      <Route path="/login" element={<Login />}>
        <Route index element={<LoginForm />}/>
        <Route path="password" element={<ResetPassword />}/>
        <Route path="verify" element={<Verify />}/>
      </Route>
      <Route path="/checkin" element={
        <CheckIn />
      }/>

      { /** MEMBER **/ }
      <Route path="/member/profile" element={
        <RequireAuth>
          <ProfilePage
            title="My Profile"
            sectionName="member"
            pageName={props.pageName}
            requireAuth
          />
        </RequireAuth>
      }/>
      <Route path="/member/password" element={
        <RequireAuth>
          <ChangePassword
            requireAuth
          />
        </RequireAuth>
      }/>
      <Route path="/member/activities" element={
        <RequireAuth>
          <ActivitiesPage
            requireAuth
          />
        </RequireAuth>
      }/>

      { /** EVENTS **/ }
      <Route path="/events" element={
        <RequireAuth>
          <ViewEventsPage
            title="Events"
            sectionName={props.sectionName}
            pageName={props.pageName}
            requireAuth
          />
        </RequireAuth>
      }/>
      <Route path="/events/current" element={
        <RequireAuth>
          <OldCurrentEvent
            title="Current Event"
            sectionName="events"
            pageName={props.pageName}
            requireAuth
          />
        </RequireAuth>
      }/>
      <Route path="/events/create" element={
        <RequireAuth>
          <CreateEventPage
            requireAuth
          />
        </RequireAuth>
      }/>
      <Route path="/events/edit/:eventID" element={
        <RequireAuth>
          <EditEventPage
            requireAuth
          />
        </RequireAuth>
      }/>
      <Route path="/events/:eventID/sessions/add" element={
        <RequireAuth>
          <CreateSessionPage
            requireAuth
          />
        </RequireAuth>
      }/>
      <Route path="/events/:eventID" element={
        <RequireAuth>
          <EventDetailsPage
            requireAuth
          />
        </RequireAuth>
      }/>
      <Route path="/events/:eventID/:sessionID" element={
        <RequireAuth>
          <SessionDetailsPage
            requireAuth
          />
        </RequireAuth>
      }/>
      <Route path="/events/:eventID/:sessionID/edit" element={
        <RequireAuth>
          <EditSessionPage
            requireAuth
          />
        </RequireAuth>
      }/>
      <Route path="/events/:eventID/:sessionID/complete" element={
        <RequireAuth>
          <CompleteSessionPage
            requireAuth
          />
        </RequireAuth>
      }/>
      <Route path="/sessions/upcoming" element={
        <RequireAuth>
          <UpcomingSessionsPage />
        </RequireAuth>
      }/>
      <Route path="/sessions/past" element={
        <RequireAuth>
          <PastSessionsPage />
        </RequireAuth>
      }/>

      { /** INEP **/ }
      <Route path="/:inep" element={<CreateEventPage />} />
    </Routes>
  );
};

export default PageRoutes;
