const TextBreaks = ({ texts }) => {
  const getText = (element, n = 0) => {
    if (Array.isArray(element)) {
      return element.filter((txt) => txt).map((txt, i) => getText(txt, n + i));
    } else {
      return (
        <div style={{ whiteSpace: 'pre-line' }} key={`text-${n}`}>
          {element}
        </div>
      );
    }
  };

  return getText(texts);
};

export default TextBreaks;
