import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { subject } from '@casl/ability';
import { useAbility } from '@casl/react';
import Grid from '@mui/material/Unstable_Grid2';
import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';
import SessionForm from '../SessionForm';
import { AbilityContext, Subjects } from '../../../../../lib/permissions';
import UserContext from '../../../../../lib/contexts/userContext';
import { PageContext } from '../../../../../lib/contexts/pageContext';
import { generateQRCode, scrollToAlert, sortDisplayOrder } from '../../../../../lib/utils';
import { createSession } from '../../../../../graphql/mutations';
import moment from '../../../../../lib/moment';
import useInvalidateQuery from '../../../../common/hooks/useInvalidateQuery';
import { generateUniqueShortCode } from '../../eventFunctions';
import useEventSessionQuery from "../../../../common/hooks/useEventSessionQuery";

const CreateSession = () => {
  const {
    setBreadcrumbValue,
    setPageMessage,
    setPageErrorMessage,
  } = useContext(PageContext);

  const [submitting, setSubmitting] = useState(false);
  const valuesSetState = useState(null);

  const { userState: { userRecord } } = useContext(UserContext);

  const ability = useAbility(AbilityContext);

  const invalidateQueries = useInvalidateQuery();

  const navigate = useNavigate();

  const { eventID } = useParams();

  const form = useForm({
    mode: 'onSubmit',
    shouldFocusError: false,
    defaultValues: {
      eventID: null,
      session_name: null,
      lesson: null,
      lessonID: null,
      curriculumID: null,
      start: null,
      end: null,
      delivery_method: null,
      delivery_url: null,
      delivery_language: null,
      material_language: null,
      delivery_location: null,
      other_details: null,
      has_wifi: null,
      demonstration: null,
      recipes: null,
      code: null,
      updated_by: null,
    }
  });

  const { data: event, loading } = useEventSessionQuery({
    permissions: {
      action: 'edit',
      subject: Subjects.EVENT,
      id: eventID,
    },
    selectFn: (events) => {
      if (!events || events.length === 0) {
        return null;
      }
      if (events.length > 1) {
        console.error('Returned more than one event!', events);
      }
      return events[0];
    },
  });

  useEffect(() => {
    if (event) {
      setBreadcrumbValue(':event', event);
      sortDisplayOrder(event?.curriculum?.lessons?.items, 'lesson_name');
    }
    // eslint-disable-next-line
  }, [event]);

  const onSubmit = async (values) => {
    setPageMessage(null);
    setPageErrorMessage(null);
    setSubmitting(true);

    // Generate a unique short code for the event check-in process
    const shortCode = await generateUniqueShortCode();
    // Generate a QR code from the event short code
    const qrCode = await generateQRCode(shortCode);

    const input = Object.keys(values).reduce((acc, key) => {
      const value = values[key];
      switch (key) {
        case 'lesson':
          if (value?.id) {
            acc[`${key}ID`] = value?.id;
          }
          if (event.event_type === 'BOOTH') {
            acc.session_name = value?.label;
          }
          break;
        case 'start':
        case 'end':
          acc[key] = moment(value).toISOString(true);
          break;
        case 'has_wifi':
        case 'delivery_method':
        case 'delivery_url':
        case 'delivery_location':
        case 'other_details':
        case 'recipes':
          acc[key] = value?.id || value;
          break;
        case 'session_name':
        case 'delivery_language':
        case 'material_language':
          acc[key] = value;
          break;
        case 'demonstration':
          acc[key] = value === 'true';
          break;
        default: break;
      }
      return acc;
    }, {
      eventID,
      completedID: null,
      updated_by: userRecord.email,
      created_by: userRecord.email,
      qr_code: qrCode,
      short_code: shortCode,
    });

    // Staff users can only assign events to themselves
    if (ability.cannot('create', subject(Subjects.SESSION, { ...input, event }))) {
      setSubmitting(false);
      setPageErrorMessage('You do not have permission to create sessions for events created by other staff members.');
      scrollToAlert();
      return false;
    }
    try {
      const result = await API.graphql({
        ...graphqlOperation(createSession, { input }),
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      await invalidateQueries('listEvents');
      setSubmitting(false);
      navigate(`/events/${event.id}/${result.data.createSession.id}`, { state: { message: 'Session created' }});
    } catch (error) {
      console.log('Error creating session: ', error);
      setSubmitting(false);
      setPageErrorMessage('Error creating session. Please try again.');
      scrollToAlert();
    }
  };

  // console.log('formData', formData);

  return (
    <Grid container gap={3} wrap={'nowrap'} columns={10}>
      <Grid xs={10}>
        {loading && (
          <Box width="100%" textAlign="center">
            <CircularProgress />
          </Box>
        )}

        {!loading && !event && (
          <>You do not have access, or the event was not found.</>
        )}

        {!loading && event && (
          <SessionForm
            form={form}
            event={event}
            session={false}
            submitting={submitting}
            onSubmit={onSubmit}
            valuesSetState={valuesSetState}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default CreateSession;
