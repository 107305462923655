import { Pages } from '../../../../../lib/constants';
import Page from '../../../Page';
import CompleteSession from './CompleteSession';
import { useState } from 'react';

export const CompleteSessionPage = () => {
  const [title, setTitle] = useState('Complete Session');
  return (
    <Page
      title={title}
      breadcrumbs={['events', ':event', ':session', 'Complete Session']}
      sectionName="sessions"
      navPhoto={{ image: '/images/sessions/CREATE_EVENT_PHOTO.jpg' }}
      pageId={Pages.SESSION_EDIT}
      requireAuth
    >
      <CompleteSession setTitle={setTitle} />
    </Page>
  )
};

export default CompleteSessionPage;
